<template>
    <div align="center" v-if="dati_caricati">
        <br>
        <div align="center">
            <span class="text-h4">Menu gestione Preventivo</span>
        </div>
        <br><br>

        <div class="row justify-center">

            <div class="col-12 col-md-6" align="center">
                <QQButton label="Modifica" color="blue-grey" icon="mdi-update" size="xl" @click.native="onModificaPreventivo()" :disable="PulsanteModificaDisattivato"/>
                <QQButton :label="label_pulsante_acquisto" color="blue-grey" icon="mdi-newspaper-variant-outline" size="xl" @click.native="onRichiestaPratica()" :disable="PreventivoNonValido() || is_quotazione_rinnovo"/>
                <QQButton label="Registro movimenti" color="blue-grey" icon="mdi-grid-off" size="xl" @click.native="onRegistroMovimenti()"/>
                <QQButton label="Annulla preventivo" v-show="PuoCancellarePreventivo()" color="blue-grey" icon="mdi-trash-can-outline" size="xl" @click.native="onCancellaPreventivo()"/>
            </div>

            <div class="col-12 col-md-5" align="left">
                <q-list separator>
                    <q-item>
                        <q-item-section>
                            <q-item-label>Operatore richiedente:</q-item-label>
                            <q-item-label caption v-html="RecordSelezionato.operatore"></q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Data emissione Preventivo:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.data}}</q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item>
                        <q-item-section>
                            <q-item-label>Descrizione:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.name}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>Preventivo numero:</q-item-label>
                            <q-item-label caption>{{RecordSelezionato.numero}}</q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item v-show="PreventivoNonValido()">
                        <q-item-section v-if="PreventivoScaduto">
                            <q-item-label>ATTENZIONE:</q-item-label>
                            <q-item-label caption>
                                Il preventivo è stato emesso da piu di 15 giorni
                                e pertanto non può essere modificato
                                o richiederne l'acquisto. In questo ultimo caso è necessario
                                procedere con un nuovo preventivo.
                            </q-item-label>
                        </q-item-section>
                        <q-item-section v-if="!is_acquistabile">
                            <q-item-label>ATTENZIONE:</q-item-label>
                            <q-item-label caption>
                                Il preventivo contiene quotazioni che non risultano ancora lavorate
                                e non pu&ograve; pertanto essere acquistato o modificato.
                            </q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>

                <h5>Proposte selezionate</h5>
                <q-list separator bordered>
                    <q-item v-for="(proposta, index) in proposte_attive" v-bind:key="index" v-bind="proposta">
                        <q-item-section>
                            <q-item-label caption>Compagnia:</q-item-label>
                            <q-item-label >{{proposta.compagnia.toUpperCase()}}</q-item-label>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label caption>Tariffa:</q-item-label>
                            <q-item-label >{{proposta.tariffa}}</q-item-label>
                        </q-item-section>
                        <q-item-section align="right">
                            <q-item-label caption>Premio:</q-item-label>
                            <q-item-label >{{getPremioProposta(proposta)}} Euro</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>

            </div>
        </div>

        <br><br><hr>
        <div class="row justify-center">
            <div class="col-md-4" align="center">
                <QQButton label="INDIETRO" color="blue-grey" icon="undo" size="md"
                    @click.native="onClickIndietro()"
                />
            </div>
        </div>
        <br><br><br><br><br><br>
    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    import policy from "@/libs/policy.js";
    import { mapFields} from "vuex-map-fields";
    import { mapState, mapActions } from "vuex";
    import store from "@/store";
    import commonLib from "@/libs/commonLib.js";

    export default {
        name: "MenuGestionePreventivi",
        data() {
            return {
                is_quotazione: false,
                is_acquistabile: false,
                quotazione: {},
                dati_caricati: false,
                is_quotazione_rinnovo: false
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapState({
                area: state => state.area,
                titolo_area: state => state.titolo_area,
                formPreventivo: state => state.formPreventivo,
                registro_movimenti: state => state.registro_movimenti,
                idpreve: state=>state.formPreventivo.preventivo.id
            }),
            //...mapGetters({
            //    formPreventivo: "formPreventivo/formPreve"
            //}),
            ...mapFields("gestionePreventivi", {
                RecordSelezionato: "RecordSelezionato",
            }),
            ...mapFields('formPreventivo', [
                "step"
            ]),
            ...mapFields('gestioneQuotazioni', [
                "idquotazione"
            ]),
            label_pulsante_acquisto() {
                if (this.is_taylormade) return "Compila questionari";
                if (this.consulenza_not_set) return "Imposta consulenza";

                return "Richiesta Acquisto";
            },
            PulsanteModificaDisattivato() {
                return this.is_quotazione || this.PreventivoNonValido();
            },
            PulsanteAcquistaDisattivato() {
                return !this.isQuotazioneAcquistabile;
            },
            PreventivoScaduto() {
                var sDataPreventivo = this.CastDateToInternationalFormat(this.RecordSelezionato.data);
                var oDataPreventivo = new Date(sDataPreventivo);
                var oOggi = new Date();
                var DifferenzaMillisecondi = (oOggi.getTime() - oDataPreventivo.getTime());
                var DifferenzaGiorni = parseInt(DifferenzaMillisecondi / (1000 * 3600 * 24));

                return (DifferenzaGiorni > 15);
            },
            prodotti_attivi() {
                //console.log("maremma prodotti attivi",this.formPreventivo);
                if (commonLib.isEmpty(this.formPreventivo)) return [];
                if (commonLib.isEmpty(this.formPreventivo.preventivo.prodotti)) return [];
                //if (commonLib.isEmpty(this.formPreventivo.preventivo.prodotti.proposte)) return [];

                //console.log("Menu gestione preventivi => prodotti_attivi:",this.formPreventivo.preventivo.prodotti);

                return this.formPreventivo.preventivo.prodotti.filter(prodotto => {
                    return prodotto.is_checked;
                });
            },
            proposte_attive() {
                var prodotti = this.prodotti_attivi;
                var proposte = [];
                for (var i=0;i<prodotti.length;i++) {
                    var prodotto = prodotti[i];
                    var elenco_proposte = prodotto.proposte.filter(proposta => {
                        return proposta.is_checked;
                    });
                    if (!commonLib.isEmpty(elenco_proposte))
                        proposte.push(elenco_proposte[0]);
                }

                return proposte;
            },
            consulenza_totale() {
                // Restituisce il valore totale della consulenza
                var totale = 0;
                var prodotti = this.prodotti_attivi;
                for (var i=0;i<prodotti.length;i++) {
                    var prodotto = prodotti[i];
                    totale += prodotto.consulenza;
                }

                return totale;
            },
            is_taylormade() {
                var prodotti = this.prodotti_attivi;

                var taylormade = prodotti.filter(p => {
                    return p.is_taylormade;
                })

                return taylormade.length > 0;
            },
            consulenza_not_set() {
                return this.consolenza_totale === 0;
            }
        },
        methods: {
            ...mapActions({
                fetchDatiPreventivo: "formPreventivo/fetchDatiPreventivo",
                fetchCancellaPreventivo: "formPreventivo/fetchCancellaPreventivo",
                fetchElencoPreventivi: "formPreventivo/fetchElencoPreventivi",
                fetchElencoMovimentiPreventivo: "formPreventivo/fetchElencoMovimentiPreventivo",
                isQuotazione: "gestioneQuotazioni/isQuotazione",
                isQuotazioneAcquistabile: "gestioneQuotazioni/isQuotazioneAcquistabile",
                fetchDatiQuotazione: "gestioneQuotazioni/fetchDatiQuotazione",
                loadQuestionariAssuntivi: "gestioneQuotazioni/loadQuestionariAssuntivi",
            }),
            onClickIndietro() {
                this.$router.push({name : "Preventivi"});
            },
            async onRegistroMovimenti() {
                var id = this.formPreventivo.preventivo.id;
                var movimenti = await this.fetchElencoMovimentiPreventivo(id);

                store.dispatch("setRegistroMovimenti",movimenti);

                //console.log("registro_movimenti:",this.registro_movimenti);

                //this.registro_movimenti = await this.fetchElencoMovimentiPreventivo(id);

                this.$router.push({name : "Preventivi.RegistroMovimenti"});
            },
            onModificaPreventivo() {
                // Verifica prima se il preventivo è stato emesso entro i 15 giorni
                // se no, la modifica viene disattivata
                if (!this.is_quotazione_rinnovo) {
                    this.step = 5;
                    this.$router.push({name : "Preventivi.FormPreventivo"});
                    return;
                }

                this.$router.push({name : "Rinnovi.AnalisiNecessita"});

            },
            async onRichiestaPratica() {
                /*if (!this.is_quotazione) {
                    // Ricarica il formPreventivo dal BE scaricandolo dal preventivo su DB
                    await this.fetchDatiPreventivo(this.RecordSelezionato.numero);
                    this.step = 8;

                    this.$router.push({name : "Preventivi.FormPreventivo"});
                } else {
                    // Verifico prima lo stato della quotazione. Salta solo se DEFINITA
                    if (this.quotazione.stato === "DEFINITA") {
                        this.$router.push({name : "Preventivi.DownloadDocumenti"});
                    } else {
                        // Visualizzo un messaggio di errore
                        this.$q.dialog({
                            title: 'Attenzione',
                            message: 'La quotazione non risulta definita e non puo` essere acquistata.',
                        });
                    }
                } */

                // TODO - Se salto direttamente sulla sezione documenti mi perdo l'email del Cliente
                //console.log("Numero preventivo:",this.RecordSelezionato.numero);
                await this.fetchDatiPreventivo(this.RecordSelezionato.numero);
                this.step = 6;

                this.$router.push({name : "Preventivi.FormPreventivo"});

            },
            onCancellaPreventivo() {
                this.$q.dialog({
                        cancel: {
                        focus: true,
                        flat: false,
                        label: "No, neanche un po`",
                        color: "primary"
                    },
                    ok: {
                        color: "secondary",
                        flat: false,
                        label: "Si, Annulla"
                    },
                    title: 'Attenzione',
                    message: 'Sei sicuro di voler annullare questo preventivo?',
                }).onOk(async () => {
                    await this.fetchCancellaPreventivo(this.RecordSelezionato.numero);
                    await this.fetchElencoPreventivi("");
                    this.$router.push({name : "Preventivi"});
                });
            },
            CastDateToInternationalFormat(data) {
                data = data.substring(0,10);        // Elimina i secondi che tanto non servono
                let elements = data.split("/");  // Splitta i tre valori numerici

                return elements[2]+"-"+elements[1]+"-"+elements[0];
            },

            PreventivoNonValido() {
                var sDataPreventivo = this.CastDateToInternationalFormat(this.RecordSelezionato.data);
                var oDataPreventivo = new Date(sDataPreventivo);
                var oOggi = new Date();
                var DifferenzaMillisecondi = (oOggi.getTime() - oDataPreventivo.getTime());
                var DifferenzaGiorni = parseInt(DifferenzaMillisecondi / (1000 * 3600 * 24));

                if (!this.is_acquistabile) return true;

                return (DifferenzaGiorni > 15);
            },
            PuoLavorarePreventivo() {
                return policy.AbilitaLavorazionePreventivi();
            },
            PuoCancellarePreventivo() {
                return policy.PuoCancellarePreventiviPropri() ||
                       policy.PuoCancellarePreventiviGruppo() ||
                       policy.PuoCancellareTuttiIPreventivi();
            },
            getImportoConsulenza(proposta) {
                var prodotto = this.prodotti_attivi.filter(prodotto => {
                    var prop = prodotto.proposte.filter(pr => {
                        return pr.tariffa === proposta.tariffa;
                    });

                    if ((!commonLib.isEmpty(prop)) && (prop.length > 0)) return true;
                });

                if (commonLib.isEmpty(prodotto)) return 0;

                return prodotto[0].consulenza;
            },
            getPremioProposta(proposta) {
                var premio = proposta.premio + proposta.diritti_aby + this.getImportoConsulenza(proposta);

                return premio.toFixed(2);
            }
        },

        async mounted() {
            if (!this.dati_caricati) {
                await this.fetchDatiPreventivo(this.RecordSelezionato.numero);
                await this.loadQuestionariAssuntivi(this.formPreventivo.preventivo.id);

                this.is_quotazione = await this.isQuotazione(this.RecordSelezionato.numero);
                this.is_acquistabile = await this.isQuotazioneAcquistabile(this.RecordSelezionato.numero);
                this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
                this.is_quotazione_rinnovo = this.formPreventivo.preventivo.isQuotazioneRinnovo;
            }

            this.dati_caricati = true;
        },

    /*    async activated() {
            // Carica anche le domande del questionario assuntivo
            await this.loadQuestionariAssuntivi(this.formPreventivo.preventivo.id);
            await this.fetchDatiPreventivo(this.RecordSelezionato.numero);
            this.is_quotazione = await this.isQuotazione(this.RecordSelezionato.numero);
            this.is_acquistabile = await this.isQuotazioneAcquistabile(this.RecordSelezionato.numero);
            this.quotazione = await this.fetchDatiQuotazione(this.idquotazione);
        } */
    }
</script>

<style scoped>
    td.domanda {
        text-align: right;
        font-style: italic;
        font-weight: bold;
    }
    td.risposta {
        border-bottom: 1px SOLID #c0c0c0;
        padding: 6px;
        width: 60%;
        vertical-align: bottom;
    }
    .table {
        width: 100%;
    }
    .table-bordered {
        border-collapse: collapse;
        width: 100%;
    }

    .table-bordered td, .table th {
        border: 1px solid #404040;
        padding: 8px;
    }

    .table-bordered th {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 8px;
        text-align: left;
        color: #404040;
        background-color: #F0F0F0;
        border: 1px SOLID #404040;

    }
</style>
